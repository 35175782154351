// CN 英文
export default {

  // logo区域
  logoArea_text_welcome: "Welcome to you",
  logoArea_text_myBalance: "balance: ",
  logoArea_btn_register: "register",
  logoArea_btn_login: "login",
  logoArea_btn_recharge: "recharge",
  logoArea_btn_logout: "logout",

  // 右上方标题区
  headerArea_btn_exitChat_title: "close chat",
  headerArea_btn_history_title: "open history chat",
  headerArea_btn_save_title: "save the chat",

  // 聊天区域
  charArea_item_notSupportAudio: "Your browser does not support audio playback",
  charArea_item_historyFileExpire: "The file generated by history has expired",

  // 文件上传区域
  uploadArea_btn_uploadFile_defaultText: "upload file",

  // 命令输入区域
  inputPanelArea_default_cmdRunFail: "run fail",
  inputPanelArea_cmdConfigNotFound: "Command does not exist",
  inputPanelArea_cmdNotFound: "Command not found!",
  inputPanelArea_cmdNotNnderstand: "The system failed to understand your intention, please try again!",
  inputPanelArea_btn_text_send: "Send",
  inputPanelArea_btn_text_sending: "Sending",
  
  pleaseLoginAfterUse: "Please log in before calling the service",
  registerSuccess: "User registration successful",
  serviceFail: "Interface request failed",
  loginExpired: "Login expired, please log in again.",
  insufficientBalance: "Balance is insufficient, please recharge.",

  // 确认框
  confirm_exitChat_title: "Are you sure you want to close the chat?",
  confirm_exitChat_content: "The chat is in progress. Forcing it to close will cause the chat history to be lost. It is recommended to save it first and then close it.",
  confirm_overlapChat_title: "Historical chat record [{{saveName}}] already exists. Are you sure you want to overwrite it?",
  confirm_overlapChat_content: "The chat is in progress. Forcing it to close will cause the chat history to be lost. It is recommended to save it first and then close it.",

  // 气泡弹窗
  pop_input_saveName_placeholder: "Please enter the save name and press <Enter> to save",

  // 普通弹窗
  modal_title_userLogin: "User Login",
  modal_title_userRegister: "User Register",
  modal_title_newUser: "Welcome!",
  modal_title_newRechange: "Member Recharge",
  nodal_content_newUser: "Dalipen is a company dedicated to advancing multimedia technology. Here, you can choose the features you want. To help you get started, we've prepared 1000 tokens for you.",
  modal_btn_newUser: "Let's Get Started!",
  modal_btn_okText: "OK",
  modal_btn_cancelText: "Cancel",
  modal_btn_loginText: "Login",
  modal_btn_rechange: "Recharge",
  modal_btn_getEmailCodeText: "Get email verification code",
  modal_btn_registerText: "Register",
  modal_input_rechangeMoney_placeholder: "Please enter the recharge amount",
  modal_form_email_label: "Email",
  modal_form_email_errorMsg: "Please input your email!",
  modal_form_password_label: "Password",
  modal_form_password_errorMsg: "Please input your password!",
  modal_form_code_label: "Verify Code",
  modal_form_code_errorMsg: "Please input your verify code!",

  // 消息提示
  message_save_success: "Save successfully",
  message_warn_isChatting: "The chat is in progress, it is recommended to save it first",
  message_error_rechange1: "Please enter the recharge amount",
  message_error_rechange2: "Please enter the correct amount, the minimum is 1",

  // 支付结果页面
  pay_result_success: "Payment successful",
  pay_result_success_message: "Thank you for your purchase!",
  pay_result_fail: "Payment failed",


  // 菜单区域
  menu_text: "Text",
  menu_text_10001: "SellMeThisPen",
  menu_text_10002: "Tiktok Video Prompt",
  menu_text_10003: "Translate",
  menu_video: "Video",
  menu_video_20001: "Template",
  menu_video_20002: "Custom Template",
  menu_video_20003: "Digital Human",
  menu_audio: "Audio",
  menu_audio_30001: "TTS",
  menu_audio_30002: "Speech To Text",
  menu_audio_30003: "Vocal Separation",
  menu_image: "Image",
  menu_image_40001: "Text To Image(SDXL)",
  menu_image_40002: "Text To Image(Ideogram)",
  menu_image_40003: "Text To Image(SD3)",
  menu_image_40004: "Swap Face",


  // 命令设置文件
  command_clean_desc: "Clear the current dialog box",
  command_content_desc: "Field-type command, used to configure output text content",
  command_mp3Upload_desc: "Upload audio MP3 file",
  command_mp3Upload_okMsg: "The audio file was uploaded successfully.\n{{audio:value}}",
  command_mp3Upload_errorMsg: "Failed to upload the audio file, please try again",
  command_mp3Upload_ext_buttonText: "Upload audio MP3 file",
  command_imageUpload_desc: "Upload Image file",
  command_imageUpload_okMsg: "The Image file was uploaded successfully.\n{{image:value}}",
  command_imageUpload_errorMsg: "Failed to upload the Image file, please try again",
  command_imageUpload_ext_buttonText: "Upload Image file",
  command_pitch_desc: "Field-type command, used to configure output pitch (default: 0)",
  command_pitch_okMsg: "Specified intonation: {{value}}",
  command_pitch_errorMsg: "The value type or format is incorrect and must be a number",
  command_empty_desc: "",
  command_empty_okMsg: "",
  command_empty_errorMsg: "",
  command_separator_desc: "Separator voice (need to execute /separator_upload command first)",
  command_separator_okMsg: "Start successfully",
  command_separator_errorMsg: "Failed to start, please try again",
  command_speechToText_desc: "Upload voice files and automatically convert them into text",
  command_speechToText_okMsg: "",
  command_speechToText_errorMsg: "",
  command_upload_a_video_ext_buttonText: "upload a video file(.mp4)",
  command_upload_a_video_desc: "Upload Video files(.mp4)",
  command_upload_a_video_okMsg: "",
  command_upload_a_video_errorMsg: "",
  command_upload_a_audio_ext_buttonText: "upload a audio file(.mp4)",
  command_upload_a_audio_desc: "Upload Audio files(.mp4)",
  command_upload_a_audio_okMsg: "",
  command_upload_a_audio_errorMsg: "",
  command_set_prompt_desc: "set prompt",
  command_set_prompt_okMsg: "",
  command_set_prompt_errorMsg: "",
  command_digital_human_desc: "generating...",
  command_digital_human_okMsg: "start generated",
  command_digital_human_errorMsg: "generated fail",
  command_speaker_desc: "Field-type command, pop-up drop-down box for tone selection",
  command_speaker_okMsg: "Tone specified:",
  command_language_okMsg: "Tone specified:",
  command_speed_desc: "Field type command, used to configure speed (default: 1)",
  command_speed_okMsg: "Specified speed: {{value}}",
  command_speed_errorMsg: "The value type or format is incorrect and must be a number",
  command_tts_desc: "Generate speech (need to execute /speaker and /content commands first)",
  command_tts_okMsg: "AI audio output:\n{{audioBlob}}",
  command_tts_errorMsg: "AI audio output failed",
  command_volume_desc: "Field-type command, used to configure output volume (default: 1)",
  command_volume_okMsg: "Volume specified: {{value}}",
  command_volume_errorMsg: "The value type or format is incorrect, must be a number!",

  command_separatorUpload_desc: "Upload MP3 file",
  command_separatorUpload_okMsg: "file uploaded successfully\n{{audio:value}}",
  command_separatorUpload_errorMsg: "Upload resource files failed, please try again",
  command_separatorUpload_ext_buttonText: "Upload MP3 resource file",

  // 页面链接
  page_link_aboutUs: "About us",
  page_link_privacyPolicy: "Privacy policy",

}